import BarDashboard from "../../components/BarDashboard/BarDashboard"


const Dashboard = () => {
    return (
        <div>
            <BarDashboard/>
        </div>
    )
}

export default Dashboard